<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
       
        <v-layout wrap justify-start>
          <v-flex xs8 pa-4 text-center>
            <span style="font-family: poppinssemibold">EMPLOYEE LIST</span>
            <v-progress-linear
              :color="appColor"
              rounded
              value="100"
              height="2px"
            ></v-progress-linear>
          </v-flex>
          <v-flex xs4>  <v-text-field outlined dense label="Search" v-model="keyword">

</v-text-field></v-flex>
          <v-flex
            xs12
            md6
            sm6
            lg3
            xl3
            align-self-center
            v-for="(item, i) in employeelist"
            :key="i"
            fill-height
            pa-4
          >
            <v-card
              tile
              elevation="2"
              outlined
              style="
                border-radius: 0px 0px 20px !important;
                border-bottom: 5px solid #5f4b8bff;
              "
            >
              <v-layout wrap justify-center py-4>
                <v-flex xs12 align-self-start text-center pa-3>
                  <!-- <span> -->
                  <!-- <v-list-item-avatar>
                    <v-img contain :src="require('./../../assets/images/avat.png')"></v-img>
                  </v-list-item-avatar> -->
                  <v-icon x-large>mdi-account-circle</v-icon>
                  <br>
                  <!-- </span> -->
                  <span style="font-family: poppinsmedium">{{
                    item.name
                  }}</span>
                </v-flex>
                <!-- </v-layout>
                <v-layout wrap justify-center py-4> -->
                <v-flex xs12 sm4 pa-2>
                  <v-btn
                    color="#CE4A7EFF"
                    style="font-family: poppinsmedium"
                    x-small
                    block
                    tile
                    dark
                    :to="'/userView?id=' + item._id"
                  >
                    <span style="color: #ffffff !important">View</span></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
    <v-layout wrap>
        <v-flex xs12  v-if="pageCount > 0">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentpage"
            color="dark green"
          ></v-pagination>
        </v-flex>
      </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      // pageCount: 1,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      page: 1,
      appLoading: false,
      editedIndex: -1,
      g: false,
      perPage: 4,
      currentpage: 1,
        limit: 12,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      employeelist: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      keyword: null,
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    this.getData();
  },
  watch: {
             // apSearch()
    // {
    //   this.keySearch();
    // }
    currentpage() {
        this.getData();
      },
      keyword()
    {
      this.getData();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    getdData() {
      this.editdialog = false;
      this.getData();
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/organization/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/superadmin/view/employeelist/fundRise",
        data: {
            page: this.currentpage,
            limit: this.limit,
         keyword:this.keyword,
          },
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeelist = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    addOrganisation() {
      var data = {};
      data["name"] = this.name;
      axios({
        url: "/add/organization",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      axios({
        url: "/organization/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
